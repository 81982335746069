import { initExportToWord } from './modules/exportToWord';
import WhenReady from './WhenReady';
import { ConfigModuleFactory } from './config/factory.config.ts';

WhenReady.whenTemplateReady(() => {
  initExportToWord();

  const config = ConfigModuleFactory.getConfig(window.templateFrontEnd.site);
  const footerAssets = config?.getFooterAssets();
  footerAssets?.forEach((footerAsset) => {
    if (footerAsset.isInjectable?.call(this)) {
      if (typeof footerAsset.asset === 'string') {
        const scriptElement = document.createElement('script');
        scriptElement.setAttribute('src', footerAsset.asset);
        if (document.body) {
          // Insert the script before the closing </body> tag
          document.body.appendChild(scriptElement);
        } else {
          console.error('Body element not found');
        }
      } else {
        (footerAsset.asset as () => unknown)();
      }
    }
  });
});
